import randomInt from './randomInt';

export default function shuffle(array) {
  const result = array.slice(0);
  for (let i = array.length - 1; i > 0; i--) {
    let j = randomInt(i + 1);
    [result[i], result[j]] = [result[j], result[i]];
  }
  return result;
}
