export default function loadImageData(url) {
  return new Promise(function(resolve, reject) {
    const sourceImage = new Image();
    sourceImage.crossOrigin = "Anonymous";
    sourceImage.onload = function() {
      const canvas = document.createElement('canvas');
      canvas.height = sourceImage.height;
      canvas.width = sourceImage.width;
      const context = canvas.getContext('2d');
      context.drawImage(sourceImage, 0, 0);
      resolve(context.getImageData(0, 0, sourceImage.width, sourceImage.height));
    };
    sourceImage.src = url;
  });
}
