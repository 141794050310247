import React, { Component, Fragment } from 'react';

import ReactGA from 'react-ga';

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

import CasinoIcon from '@material-ui/icons/Casino';
import SettingsIcon from '@material-ui/icons/Settings';
import SaveIcon from '@material-ui/icons/Save';

import PickColorDialog from '../PickColorDialog';
import hexToName from '../utils/hexToName';
import textColorForBackground from '../utils/textColorForBackground';

class CustomColor extends Component {
  state = {
    showDialog: false
  }
  onClick = () => {
    this.setState({ showDialog: true });
  }
  onClose = () => {
    this.setState({ showDialog: false });
  }
  render() {
    const { description, value } = this.props;
    return <Fragment>
      <ListItem onClick={this.onClick} style={{ backgroundColor: value, cursor: 'pointer', maxWidth: '33%', minHeight: 100 }}>
        <ListItemText primary={description} secondary={<span style={{ opacity: 0.8 }}>{hexToName(value)} {value}</span>} primaryTypographyProps={{ style: { color: textColorForBackground(value) }}} secondaryTypographyProps={{ style: { color: textColorForBackground(value) }}}/>
      </ListItem>
      <PickColorDialog value={value} open={this.state.showDialog} onChange={this.props.onChange} onClose={this.onClose} />
    </Fragment>
  }
}

export default class Controls extends Component {
  randomScheme = () => {
    this.props.onRandomizeTheme();
  }
  changeColor = (value, i) => {
    this.props.onChangeColor(value, i);
    ReactGA.event({ category: 'Edit Theme', action: 'Change Color' });
  }
  render() {
    const { colors, theme } = this.props;
    const customColors = colors.filter(({ color, theme }) => { return theme === 0 && color });
    return <Grid container>
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom>Theme</Typography>
        <List style={{ display: 'flex', flexWrap: 'wrap' }}>
          {theme.map(({ color, label }, i) => {
            return <CustomColor key={i} description={label} value={color} onChange={(value) => { this.changeColor(value, i) }}/>
          })}
        </List>
      </Grid>
      <Grid item xs={12} style={{ marginBottom: 16, marginTop: 16 }}>
        <Button variant="contained" style={{ marginRight: 16, marginBottom: 16 }} color="primary" onClick={this.randomScheme}>Randomize! <CasinoIcon style={{ marginLeft: 8 }}/></Button>
        <Button variant="contained" style={{ marginRight: 16, marginBottom: 16 }} onClick={this.props.onSaveImage}>Save Image <SaveIcon style={{ marginLeft: 8 }} /></Button>
        <Button variant="contained" style={{ marginRight: 16, marginBottom: 16 }} onClick={this.props.onShowConfigure}>Configure <SettingsIcon style={{ marginLeft: 8 }} /></Button>
      </Grid>
      {customColors.length > 0 &&
        <Grid item xs={12} style={{ marginBottom: 16, marginTop: 16 }}>
          <Typography variant="h5" gutterBottom>Custom Colors</Typography>
          <List style={{ display: 'flex', flexWrap: 'wrap' }}>
            {customColors.map(({ key, description, color }) => {
              return <CustomColor key={key} description={description} value={color} onChange={(value) => { this.props.onChangeMapping(key, { color: value }) }}/>;
            })}
          </List>
        </Grid>
      }
    </Grid>;
  }
}
