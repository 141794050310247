import React, { Component } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';

import { storage } from './initFirebase';

export default class AddImage extends Component {
  avatarSelected = (event) => {
    let file = event.target.files[0];
    let ref = storage.ref().child(`/uploads/${new Date().getTime()}`);
    ref.put(file).then(snapshot => {
      snapshot.ref.getDownloadURL().then(imageUrl => {
        this.props.onImageUploaded(imageUrl);
      });
    });
  }
  render() {
    return <div style={{ display: 'flex' }}>
      <Avatar style={{ backgroundColor: '#424242' }} src={this.props.imageUrl}> </Avatar>
      <input
        accept="image/*"
        style={{ display: 'none' }}
        id={this.props.id}
        type="file"
        onChange={this.avatarSelected}
      />
      <label htmlFor={this.props.id}>
        <Button component="span" style={{ marginLeft: 16 }}>Add Image</Button>
      </label>
    </div>;
  }
}
