import React, { Component } from 'react';

import { Redirect } from 'react-router';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import grey from '@material-ui/core/colors/grey';

import { firestore } from '../initFirebase';
import colorPalette from '../utils/colorPalette';
import extractColors from '../utils/extractColors';
import loadImageData from '../utils/loadImageData';
import hexToRGB from '../utils/hexToRGB';
import ImagePreview from '../ThemeEditor/ImagePreview';
import Logo from '../Logo';
import UploadImage from '../UploadImage';

export default class NewMapping extends Component {
  state = {
    baseImage: null,
    baseUrl: null,
    colorMappings: {},
    location: null,
    themeMappings: {},
    themes: colorPalette().map((color, i) => {
      return {
        label: `Theme ${i + 1}`,
        color,
      }
    }),
    descriptions: {},
    maskUrl: null,
    maskColors: [],
    maskImage: null,
  }
  onBaseUploaded = (baseUrl) => {
    this.setState({ baseUrl });
    loadImageData(baseUrl).then((baseImage) => {
      this.setState({ baseImage });
    });
  }
  onMaskUploaded = (maskUrl) => {
    this.setState({ maskUrl });
    loadImageData(maskUrl).then(this.onMaskLoaded);
  }
  onMaskLoaded = (maskImage) => {
    const maskColors = extractColors(maskImage);
    this.setState({ maskColors, maskImage });
  }
  changeDescription(maskColor, description) {
    const descriptions = Object.assign({}, this.state.descriptions, { [maskColor]: description });
    this.setState({ descriptions });
  }
  changeThemeMapping(maskColor, themeMapping) {
    const themeMappings = Object.assign({}, this.state.themeMappings, { [maskColor]: themeMapping });
    this.setState({ themeMappings });
  }
  changeColorMapping(maskColor, color) {
    const colorMappings = Object.assign({}, this.state.colorMappings, { [maskColor]: color });
    this.setState({ colorMappings });
  }
  colorMap() {
    const { colorMappings, maskColors, themeMappings, themes } = this.state;
    const result = {};
    maskColors.forEach((color) => {
      const selectedTheme = themeMappings[color];
      if (selectedTheme > 0) {
        result[hexToRGB(color).join(',')] = hexToRGB(themes[selectedTheme - 1].color);
      } else if (colorMappings[color]) {
        result[hexToRGB(color).join(',')] = hexToRGB(colorMappings[color]);
      }
    });
    return result;
  }
  changeThemeDescription(i, label) {
    const { themes } = this.state;
    const newThemes = themes.slice(0);
    newThemes[i] = Object.assign({}, themes[i], { label });
    this.setState({ themes: newThemes });
  }
  randomizeTheme = () => {
    const newPalette = colorPalette();
    const { themes } = this.state;
    const newThemes = themes.map((theme, i) => {
      return Object.assign({}, theme, { color: newPalette[i] });
    })
    this.setState({ themes: newThemes });
  }
  save = () => {
    const { baseUrl, colorMappings, descriptions, maskColors, maskUrl, themeMappings, themes } = this.state;
    let colors = [];
    maskColors.forEach((maskColor) => {
      const description = descriptions[maskColor];
      const themeMapping = themeMappings[maskColor] || 0;
      if (description && description.length > 0) {
        const colorMapping = {
          theme: themeMapping,
          description,
          key: maskColor,
        };
        if (!themeMapping) {
          colorMapping.color = colorMappings[maskColor] || maskColor;
        }
        colors.push(colorMapping);
      }
    });
    colors = colors.sort((c1, c2) => {
      return c1.description.localeCompare(c2.description);
    });
    const document = {
      baseUrl: baseUrl,
      mappingUrl: maskUrl,
      theme: themes,
      colors,
      model: {
        line: "Judgement",
        lineUrl: "https://judgement.game",
        name: "Dor'Gokaan",
        modelUrl: "https://judgement.game/blogs/news/tagged/dorgokaan",
      },
      save: {
        imageUrl: baseUrl,
        title: "Good Morning, Sunshine",
      }
    };
    firestore.collection('themes').add(document).then((result) => {
      this.setState({ location: `/themes/${result.id}` });
    });
  }
  render() {
    const { baseImage, colorMappings, descriptions, location, maskImage, maskUrl, themeMappings, themes } = this.state;
    if (location) {
      return <Redirect to={location} />
    }
    return <Container maxWidth={false}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Grid container spacing={1} style={{ height: '100vh', overflowY: 'auto', display: 'block' }}>
            <Grid item xs={12}>
              <Logo />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">
                Add a new model
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl margin="normal">
                <FormLabel htmlFor="base-image" style={{ marginBottom: 8 }}>Base Image</FormLabel>
                <UploadImage id="base-image" imageUrl={this.state.baseUrl} onImageUploaded={this.onBaseUploaded} />
                <FormHelperText id="base-image-helper-text">A black and white photo of the model. I recommend less than 1024 pixels in size, larger images slow down the system a bit.</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl margin="normal">
                <FormLabel htmlFor="mask-image-input" style={{ marginBottom: 8 }}>Mask Image</FormLabel>
                <UploadImage id="mask-image" imageUrl={this.state.maskUrl} onImageUploaded={this.onMaskUploaded} />
                <FormHelperText id="mask-image-helper-text">Transparent PNG with one color for each region. Make sure you don't use anti-aliasing or you'll get a lot of one pixels regions.</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">
                Theme
              </Typography>
              {themes.map((theme, i) => {
                return <div key={i} style={{ display: "flex" }}>
                  <Avatar style={{ backgroundColor: theme.color, marginRight: 16 }} />
                  <TextField label="Label" value={theme.label} style={{ marginRight: 16 }} onChange={(e) => { this.changeThemeDescription(i, e.target.value) }} />
                </div>
              })}
            </Grid>
            <Grid item xs={12}>
              <Button onClick={() => { this.randomizeTheme() }}>Randomize</Button>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">
                Color Mapping
              </Typography>
            </Grid>
            {this.state.maskColors.map((maskColor) => {
              const selectedTheme = themeMappings[maskColor] || 0;
              const selectedColor = colorMappings[maskColor] || '#888888';
              return <Grid item xs={12} key={maskColor} style={{ display: 'flex' }}>
                <Avatar style={{ backgroundColor: maskColor, marginRight: 16 }} />
                <TextField label={maskColor} value={descriptions[maskColor] || ""} style={{ marginRight: 16 }} onChange={(e) => { this.changeDescription(maskColor, e.target.value) }} />
                <Select value={selectedTheme} onChange={(e) => { this.changeThemeMapping(maskColor, e.target.value) } }>
                  <MenuItem value={0}>
                    <em>Custom</em>
                  </MenuItem>
                  {themes.map((theme, i) => {
                    return <MenuItem value={i + 1}>{theme.label}</MenuItem>
                  })}
                </Select>
                <input type="color" value={selectedColor} onChange={(e) => this.changeColorMapping(maskColor, e.target.value) } />
              </Grid>
            })}
            <Grid item xs={12}>
              <Button variant="contained" color="primary" onClick={() => { this.save() }}>Save</Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={4} style={{ backgroundColor: grey[300], minHeight: '100vh' }}>
          {maskUrl &&
            <img alt="mask" src={maskUrl} style={{ objectFit: 'contain', height: '100%', width: '100%' }} />
          }
        </Grid>
        <Grid item xs={12} sm={4} style={{ backgroundColor: grey[300], minHeight: '100vh' }}>
          <ImagePreview colorMap={this.colorMap()} baseImage={baseImage} maskImage={maskImage}/>
        </Grid>
      </Grid>
    </Container>
  }
}
