import namer from 'color-namer';

import { P3 } from './paints';

const p3ColorMap = {};
P3.forEach(({ color, name }) => {
  p3ColorMap[color] = name;
});

export default function hexToName(hex) {
  const p3color = p3ColorMap[hex];
  if (p3color) return p3color;
  return namer(hex, { pick: ['ntc'] }).ntc[0].name;
}
