import React, { Component } from 'react';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import ListThemes from '../ListThemes';
import Logo from '../Logo';

export default class Home extends Component {
  render() {
    const tag = this.props.match.params.tag;
    return <Container>
      <Grid container>
        <Grid item xs={12}>
          <Logo />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>Tag: {tag}</Typography>
          <ListThemes tag={tag} />
        </Grid>
      </Grid>
    </Container>
  }
}
