export default function hexToRGB(hex) {
  if (hex.charAt(0) === '#') {
    hex = hex.substring(1, 7);
  }
  return [
    parseInt(hex.substring(0, 2), 16),
    parseInt(hex.substring(2, 4), 16),
    parseInt(hex.substring(4, 6), 16),
  ];
}
