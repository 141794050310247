import React from 'react';

import { Helmet } from 'react-helmet';

export default function Meta(props) {
  const { model, save } = props;
  if (!model || !save) {
    return null;
  }
  const title = `${model.name}: ${save.title}`;
  return <Helmet>
    <title>{title}</title>
    <meta property="og:type" content="website" />
    <meta property="og:image" content={save.imageUrl} />
    <meta property="og:title" content={title} />
    <meta property="og:url" content={window.location.href} />
    <meta property="og:site_name" content="Mini Schemer" />
  </Helmet>
}
