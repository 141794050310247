import ColorScheme from 'color-scheme';

import randomElement from './randomElement';
import randomInt from './randomInt';
import shuffle from './shuffle';

const SCHEMES = ['mono', 'contrast', 'triade', 'tetrade', 'analogic'];
const VARIATIONS = ['default', 'pastel', 'soft', 'light', 'hard', 'pale'];

export default function colorPalette() {
  const hue = randomInt(256);
  const scheme = randomElement(SCHEMES);
  const variation = randomElement(VARIATIONS);

  let colors = new ColorScheme()
    .from_hue(hue)
    .scheme(scheme)
    .variation(variation)
    .colors();
  while (colors.length < 6) {
    colors.push('888888');
  }
  return shuffle(colors).slice(0, 6).map((color) => { return '#' + color.toUpperCase() });
}
