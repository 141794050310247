import React, { Component } from 'react';

import { Link } from 'react-router-dom';


import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import ListThemes from '../ListThemes';
import Logo from '../Logo';

const COLLECTIONS = ['Judgement', 'Warmahordes'];

export default class Home extends Component {
  render() {
    return <Container>
      <Grid container>
        <Grid item xs={12}>
          <Logo />
        </Grid>
        <Grid item xs={12} style={{ marginBottom: 16 }}>
          <Typography variant="h5" gutterBottom>Choose a model</Typography>
          <ListThemes tag="Home" />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>Browse by collection</Typography>
          <ul>
            {COLLECTIONS.map((tag) => {
              return <li>
                <Link to={`/tags/${tag}`}>{tag}</Link>
              </li>
            })}
          </ul>
        </Grid>
      </Grid>
    </Container>
  }
}
