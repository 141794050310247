import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";

import Home from './Home';
import NewMapping from './NewMapping';
import TagListing from './TagListing';
import ThemeEditor from './ThemeEditor';

function App() {
  return (
    <Router>
      <Route path="/" exact component={Home} />
      <Route path="/new" component={NewMapping} />
      <Route path="/tags/:tag" component={TagListing} />
      <Route path="/themes/:id" component={ThemeEditor} />
    </Router>
  );
}

export default App;
