export default function overlay(target, blend) {
  // Formula from http://www.deepskycolors.com/archive/2010/04/21/formulas-for-Photoshop-blending-modes.html
  // (Target > 0.5) * (1 - (1-2*(Target-0.5)) * (1-Blend)) +
  // (Target <= 0.5) * ((2*Target) * Blend)
  const targetScaled = target / 255.0;
  const blendScaled = blend / 255.0;

  if (targetScaled > 0.5) {
    return 255 * (1 - (1 - 2 * (targetScaled - 0.5)) * (1 - blendScaled));
  }
  return 255 * 2 * targetScaled * blendScaled;
}
