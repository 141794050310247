import React from 'react';

import { Link } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import grey from '@material-ui/core/colors/grey';

import randomElement from './utils/randomElement';

const TAGLINES = [
  'Let’s make those man-dollies fabulous!',
  // 'What do we say to studio schemes? Not today!',
  // 'Pink? Nah. Hot pink? Oh, hell yes.',
  // 'Brown and grey? Really?',
];
const TAGLINE = randomElement(TAGLINES);

export default function Logo() {
  return <Link to="/" style={{ textDecoration: 'none' }}>
    <Typography variant="h2" align="center" style={{ marginTop: 20, color: grey[700], fontFamily: 'Shojumaru'}}>Mini Schemer</Typography>
    <Typography paragraph align="center" color="textSecondary"><span role="img" aria-label="rainbox">🌈</span> <em>{TAGLINE}</em> <span role="img" aria-label="rainbox">🌈</span></Typography>
  </Link>
}
