import React from 'react';
import ReactGA from 'react-ga';

import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';


export default class ModelInfo extends React.Component {
  linkClicked(linkName) {
    ReactGA.event({ category: 'External Link', action: linkName });
  }
  render() {
    const { model } = this.props;
    return <>
      <Typography variant="h5" style={{ marginTop: 16 }} gutterBottom>Model</Typography>
      {model.modelUrl &&
        <Typography paragraph>
          <Link onClick={() => { this.linkClicked('Model Name') }} href={model.modelUrl} target="_blank" rel="noopener">{model.name}</Link> from <Link onClick={() => { this.linkClicked('Model Line') }} href={model.lineUrl} target="_blank" rel="noopener">{model.line}</Link>
        </Typography>
      }
      <Typography paragraph>
        <Button onClick={() => { this.linkClicked('Buy Now') }} variant="contained" href={model.modelUrl} target="_blank" rel="noopener">Buy Now <ShoppingCartIcon style={{ marginLeft: 8 }} /></Button>
      </Typography>
      <Typography paragraph>
        Have another model you’d like to try scheming? Shoot me a message at <Link href="mailto:lars@wartable.online">lars@wartable.online</Link>.
      </Typography>
    </>
  }

}
