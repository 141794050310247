import React, { Component } from 'react';

import { Redirect } from "react-router";

import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';

import { firestore } from './initFirebase';

export default class ListThemes extends Component {
  state = {
    location: null,
    themes: [],
  }
  componentDidMount() {
    firestore.collection('themes').where('tags', 'array-contains', this.props.tag).orderBy("model.name").limit(50).get().then((querySnapshot) => {
      const themes = [];
      querySnapshot.forEach((doc) => {
        themes.push({ id: doc.id, data: doc.data() });
      });
      this.setState({ themes });
    });
  }
  navigateTo(location) {
    this.setState({ location });
  }
  render() {
    const { location, themes } = this.state;
    if (location) {
      return <Redirect push to={location} />;
    }
    return <GridList cellHeight={400} cols={Math.round(window.innerWidth / 300)}>
      {themes.map(({ id, data }) => {
        const model = data.model || {};
        return <GridListTile style={{ cursor: 'pointer' }} onClick={() => { this.navigateTo(`/themes/${id}`) }}>
          <img src={data.baseUrl} alt={model.name} />
          <GridListTileBar
            title={model.name}
            subtitle={model.line}
          />
        </GridListTile>
      })}
    </GridList>
  }
}
