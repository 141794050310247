import React from 'react';

import overlay from '../utils/overlay';

export default class ImagePreview extends React.Component {
  constructor(props) {
    super(props);
    this.canvasElement = React.createRef();
  }
  componentDidUpdate() {
    const { baseImage, colorMap, maskImage } = this.props;
    if (!baseImage || !maskImage) {
      return;
    }
    const canvas = this.canvasElement.current;
    canvas.height = maskImage.height;
    canvas.width = maskImage.width;

    const newData = new Uint8ClampedArray(baseImage.data.length);
    for (let i = 0; i < baseImage.data.length; i += 4) {
      let baseColor = baseImage.data.slice(i, i + 3);
      let maskColor = maskImage.data.slice(i, i + 3);
      let color = baseColor;
      let maskKey = maskColor.join(',');
      if (colorMap[maskKey]) {
        color = colorMap[maskKey];
      }

      for (let j = 0; j < 3; j++) {
        if (maskImage.data[i + 3] > 0) {
          newData[i + j] = overlay(baseColor[j], color[j]);
        } else {
          newData[i + j] = baseColor[j];
        }
      }
      newData[i + 3] = baseImage.data[i + 3];
    }
    const context = canvas.getContext('2d');
    context.putImageData(new ImageData(newData, maskImage.width, maskImage.height), 0, 0);
  }
  render() {
    return <canvas ref={this.canvasElement} style={{ objectFit: 'contain', width: '100%', height: '100%' }}></canvas>;
  }
}
