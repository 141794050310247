import React, { Component } from 'react';

import { ChromePicker } from 'react-color';

import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemText from '@material-ui/core/ListItemText';

import MobileFriendlyDialog from './MobileFriendlyDialog';

import textColorForBackground from './utils/textColorForBackground';

import { P3 } from './utils/paints';
export default class PickColorDialog extends Component {
  render() {
    return <MobileFriendlyDialog open={this.props.open} onClose={this.props.onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Pick Color</DialogTitle>
      <DialogContent>
        <div style={{ marginBottom: 16 }}>
          <InputLabel style={{ marginBottom: 8 }}htmlFor="hex-color-picker">Hex Value</InputLabel>
          <ChromePicker color={this.props.value} onChangeComplete={(color) => { this.props.onChange(color.hex) } } />
        </div>

        <List>
          <ListSubheader style={{ backgroundColor: 'white' }}>Formula P3</ListSubheader>
          {P3.map(({ color, name }) => {
            const textColor = textColorForBackground(color);
            const selected = color === this.props.value;
            return (
              <ListItem selected={selected} key={color} style={{ cursor: 'pointer', backgroundColor: color, color: textColor }} onClick={() => { this.props.onChange(color) }}>
                <ListItemText primaryTypographyProps={{ style:  { fontWeight: selected ? 600 : 400 }}} primary={name}/>
              </ListItem>
            );
          })}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={this.props.onClose} color="primary" variant="contained">Close</Button>
      </DialogActions>
    </MobileFriendlyDialog>
  }
}
