export default function rgbToHex(rgb) {
  return '#' + scalarToHex(rgb[0]) + scalarToHex(rgb[1]) + scalarToHex(rgb[2]);
}
function scalarToHex(rgb) {
  var hex = Number(rgb).toString(16);
  if (hex.length < 2) {
    hex = "0" + hex;
  }
  return hex.toUpperCase();
}
