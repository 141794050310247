import React from 'react';

import ReactGA from 'react-ga';

import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import grey from '@material-ui/core/colors/grey';

import ConfigureMappings from './ConfigureMappings';
import Controls from './Controls';
import ImagePreview from './ImagePreview';
import Logo from '../Logo';
import Meta from './Meta';
import ModelInfo from './ModelInfo';
import { firestore } from '../initFirebase';

import colorPalette from '../utils/colorPalette';
import hexToRGB from '../utils/hexToRGB';
import loadImageData from '../utils/loadImageData';

export default class ThemeEditorPage extends React.Component {
  state = {
    model: {},
    theme: [],
    colors: [],
    maskColors: [],
    showConfigure: false,
  }
  constructor(props) {
    super(props);
    firestore.collection('themes').doc(props.match.params.id).get().then(this.onMappingLoaded);
  }
  onMappingLoaded = (doc) => {
    const mapping = doc.data();
    this.setState({ baseUrl: mapping.baseUrl, mappingUrl: mapping.mappingUrl, theme: mapping.theme, save: mapping.save, colors: mapping.colors, model: mapping.model });
    loadImageData(mapping.baseUrl).then((baseImage) => {
      this.setState({ baseImage });
    });
    loadImageData(mapping.mappingUrl).then((maskImage) => {
      this.setState({ maskImage });
    });
  }
  save = () => {
    const document = {
      baseUrl: this.state.baseUrl,
      mappingUrl: this.state.mappingUrl,
      theme: this.state.theme,
      save: this.state.save,
      colors: this.state.colors,
      model: this.state.model
    };
    firestore.collection('themes').add(document).then((result) => {
      window.location.href = `/themes/${result.id}`;
    });
  }
  onChangeColor = (color, i) => {
    let theme = this.state.theme.slice(0);
    theme[i] = Object.assign({}, theme[i], { color });
    this.setState({ theme });
  }
  randomTheme = () => {
    ReactGA.event({ category: 'Edit Theme', action: 'Randomize Theme' });
    const { theme } = this.state;
    const colors = colorPalette();
    let newTheme = colors.map((color, i) => {
      return Object.assign({}, theme[i], { color });
    });
    this.setState({ theme: newTheme });
  }
  colorMap() {
    const { colors, theme } = this.state;
    const colorMap = {};
    colors.forEach((mapping) => {
      if (mapping.theme) {
        colorMap[hexToRGB(mapping.key)] = hexToRGB(theme[mapping.theme - 1].color);
      } else if (mapping.color !== undefined) {
        colorMap[hexToRGB(mapping.key)] = hexToRGB(mapping.color);
      }
    });
    return colorMap;
  }
  onChangeMapping = (color, mapping) => {
    const newColors = this.state.colors.slice(0);
    const index = newColors.findIndex((mapping) => {
      return mapping.key === color;
    });
    newColors[index] = Object.assign({}, newColors[index], mapping);
    this.setState({ colors: newColors });
  }
  onSaveImage = () => {
    ReactGA.event({ category: 'Save', action: 'Download Image' });
    const canvas = document.getElementsByTagName('canvas')[0];
    canvas.toBlob((blob) => {
      const link = document.createElement("a");
      link.download = `${this.state.model.name}.jpg`;
      link.href = URL.createObjectURL(blob);
      link.click();
    }, 'image/jpg')
  }
  render() {
    const { colors, model, save, theme } = this.state;
    return <>
      <CssBaseline />
      <Meta model={model} save={save} />
      <Grid container style={{ flexWrap: 'wrap-reverse' }}>
        <Grid item xs={12} md={6}>
          <Container>
            <Controls colors={colors} theme={theme} onChangeMapping={this.onChangeMapping} onChangeColor={this.onChangeColor} onRandomizeTheme={this.randomTheme} onSaveImage={this.onSaveImage} onShowConfigure={() => { this.setState({ showConfigure: true })}}/>
            <ConfigureMappings themes={theme} mapping={colors} open={this.state.showConfigure} onChangeMapping={this.onChangeMapping} onClose={() => { this.setState({ showConfigure: false })}}  />
            <ModelInfo model={model}/>
          </Container>
        </Grid>
        <Grid item xs={12} md={6} style={{ backgroundColor: grey[300], display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
          <ImagePreview colorMap={this.colorMap()} baseImage={this.state.baseImage} maskImage={this.state.maskImage} />
        </Grid>
        <Grid item xs={12}>
          <Logo />
        </Grid>
      </Grid>
    </>
  }
}
