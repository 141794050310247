import React, { Component } from 'react';

import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import MobileFriendlyDialog from '../MobileFriendlyDialog';

export default class ConfigureMappings extends Component {
  onChangeTheme = (color, e) => {
    const attrs = { theme: parseInt(e.target.value) };
    if (attrs.theme === 0) {
      attrs.color = '#000000';
    }
    this.props.onChangeMapping(color, attrs);
  }
  render() {
    const { onClose, open, mapping, themes } = this.props;
    return <MobileFriendlyDialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>Change Mappings</DialogTitle>
      <DialogContent>
        <List>
          {mapping.map(({ color, description, key, theme }) => {
            return <ListItem>
              <ListItemText primary={description} />
              <Select style={{ minWidth: '33%' }} value={theme || 0} onChange={(e) => { this.onChangeTheme(key, e) } }>
                <MenuItem value={0}>
                  <em>Custom</em>
                </MenuItem>
                {themes.map((theme, i) => {
                  return <MenuItem key={i + 1} value={i + 1}>{theme.label}</MenuItem>
                })}
              </Select>
            </ListItem>
          })}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={this.props.onClose} color="primary" variant="contained">Close</Button>
      </DialogActions>
    </MobileFriendlyDialog>
  }
}
