import rgbToHex from './rgbToHex';

export default function extractColors(image) {
  let uniqueColors = {};
  for (let i = 0; i < image.data.length; i += 4) {
    let color = image.data.slice(i, i + 3);
    let alpha = image.data[i + 3];
    if (alpha > 0) {
      let hex = rgbToHex(color);
      if (!uniqueColors[hex]) {
        uniqueColors[hex] = 0;
      }
      uniqueColors[hex] += 1;
    }
  }
  const result = Object.entries(uniqueColors).sort((kv1, kv2) => {
    return kv2[1] - kv1[1];
  }).filter((kv) => {
    return kv[1] > 5;
  }).map((kv) => {
    return kv[0];
  });
  return result;
}
