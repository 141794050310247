import * as firebase from 'firebase/app';
import 'firebase/firestore'; // eslint-disable-line no-unused-vars
import 'firebase/storage';

const app = firebase.initializeApp({
  apiKey: 'AIzaSyAWUt8-4Y-vsVTCe5IAMI6ybe548YohqvE',
  authDomain: 'minischemer.firebaseapp.com',
  databaseURL: 'https://minischemer.firebaseio.com',
  messagingSenderId: '812336191623',
  projectId: 'minischemer',
  storageBucket: 'minischemer.appspot.com',
});
export const firestore = firebase.firestore(app);
export const storage = firebase.storage();
