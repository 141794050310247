import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';
import withMobileDialog from '@material-ui/core/withMobileDialog';

const styles = theme => ({
  paper: {
    padding: 'env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left)',
  }
});

class MobileFriendlyDialog extends Component {
  render() {
    let { classes, fullScreen } = this.props;
    return <Dialog classes={{ paper: classes.paper }} fullScreen={fullScreen} {...this.props} />
  }
}

export default withStyles(styles)(withMobileDialog()(MobileFriendlyDialog));
