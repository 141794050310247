export const P3 =  [
  { name: 'Umbral Umber', color: '#1C0E01' },
  { name: 'Battlefield Brown', color: '#27221C' },
  { name: 'Sanguine Base', color: '#551C1B' },
  { name: 'Bloodstone', color: '#8C442C' },
  { name: 'Skorne Red', color: '#9B272C' },
  { name: 'Khador Red Base', color: '#D73031' },
  { name: 'Inferno Orange', color: '#DF5E3D' },
  { name: 'Khador Red Highlight', color: '#E3884E' },
  { name: 'Heartfire', color: '#EAA854' },
  { name: 'Ember Orange', color: '#CE803B' },
  { name: 'Meaty Ochre', color: '#AC773B' },
  { name: 'Gun Corps Brown', color: '#87754A' },
  { name: 'Rucksack Tan', color: '#A78E47' },
  { name: 'Moldy Ochre', color: '#D2AD4C' },
  { name: 'Sulfuric Yellow', color: '#EACC4C' },
  { name: 'Cygnus Yellow', color: '#F2E24C' },
  { name: 'Menoth White Base', color: '#EEECB8' },
  { name: 'Menoth White Highlight', color: '#F4F2D9' },
  { name: 'Battle Dress Green', color: '#6B6936' },
  { name: 'Bog Moss', color: '#7D7A46' },
  { name: 'Hammerfall Khaki', color: '#C1B892' },
  { name: '’Jack Bone', color: '#D1CDA4' },
  { name: 'Gnarls Green', color: '#1A5630' },
  { name: 'Iosan Green', color: '#2A9B52' },
  { name: 'Necrotite Green', color: '#7AB94F' },
  { name: 'Wurm Green', color: '#88B549' },
  { name: 'Eldritch', color: '#007055' },
  { name: 'Thornwood Green', color: '#5B5C46' },
  { name: 'Ordic Olive', color: '#68763A' },
  { name: 'Traitor Green', color: '#788761' },
  { name: 'Exile Blue', color: '#222D50' },
  { name: 'Cygnar Blue Base', color: '#204381' },
  { name: 'Cygnar Blue Highlight', color: '#346BA3' },
  { name: 'Coal Black', color: '#0F5054' },
  { name: 'Trollblood Base', color: '#277F89' },
  { name: 'Meredius Blue', color: '#00A5A7' },
  { name: 'Arcane Blue', color: '#7EC8CA' },
  { name: 'Underbelly Blue', color: '#BCCECE' },
  { name: 'Frostbite', color: '#E7F0F2' },
  { name: 'Beaten Purple', color: '#482A5D' },
  { name: 'Murderous Magenta', color: '#942753' },
  { name: 'Sanguine Highlight', color: '#B34653' },
  { name: 'Carnal Pink', color: '#F8DEE4' },
  { name: 'Bogrin Brown', color: '#8C5B39' },
  { name: 'Bootstrap Leather', color: '#87613D' },
  { name: 'Blood Tracker Brown', color: '#976333' },
  { name: 'Beast Hide', color: '#A58D67' },
  { name: 'Thrall Flesh', color: '#CBD3A7' },
  { name: 'Sickly Skin', color: '#E6E4B5' },
  { name: 'Ryn Flesh', color: '#F7DFCB' },
  { name: 'Midlund Flesh', color: '#E3A280' },
  { name: 'Khardic Flesh', color: '#BA7359' },
  { name: 'Idrian Flesh', color: '#8C573B' },
  { name: 'Gravedigger Denim', color: '#424C4B' },
  { name: 'Greatcoat Grey', color: '#485054' },
  { name: 'Ironhull Grey', color: '#858C8E' },
  { name: 'Cryx Bane Base', color: '#2C3325' },
  { name: 'Bastion Grey', color: '#6F725E' },
  { name: 'Cryx Bane Highlight', color: '#888774' },
  { name: 'Trollblood Highlight', color: '#B2AD99' },
  { name: 'Thamar Black', color: '#0A0B09' },
  { name: 'Morrow White', color: '#FFFDFD' },
];
